:root {
  --primary: #293970;
  --secondary: #39509F;
  --accent: #FF7F63;
  --accent-variant: #ff441a;
  --cancel: #c2ac0a;
  --error: #b32100;

  /* greys */
  --grey: #C1C1C1;
  --grey_900: #0d0d0d;
  --grey_800: #262626;
  --grey_700: #404040;
  --grey_600: #595959;
  --grey_500: #737373;
  --grey_400: #8c8c8c;
  --grey_300: #a6a6a6;
  --grey_200: #bfbfbf;
  --grey_100: #cccccc;
  --grey_50: #d9d9d9;
  --grey_25: #e6e6e6;
  --grey_10: #f2f2f2;

  /* functional colors */
  --page-background: #FFF5EC;
  --component-background: #d9d9d9;
  --component-background-black: #262626;
  --component-background-darker: #595959;
  --component-background-dark: #8c8c8c;
  --component-background-light: #f2f2f2;
  --icons: #595959;
  --icons-variant: #293970;
  --text-grey: #a6a6a6;
  /* also used for dividers */
  --borders: #cccccc;

  /* Hover Colors */
  --primary-darkened: #212e5a;
  --secondary-darkened: #28386f;
  --accent-darkened: #470d00;
  --cancel-darkened: #9b8a08;
  --borders-darkened: #a3a3a3;
  --component-background-light-darkened: #c2c2c2;
  --component-background-black-darkened: #1e1e1e;

  /* look into removing */
  --orange-darkened: #cc6e00;

  /* common */
  --white: #ffffff;
  --black: #000000;

  /* Sizes */
  --phone: 481px;
  --tablet: 961px;
  --desktop: 1025px;
  --large-desktop: 1281px;

  /* TODO: Look into removing this */
  --orange: #FF8900;

  /* Icon colors */
  --blue: #2e2e6c;
  --pink: #ff6680;
  --green: #08ac8c;
  --yellow: #ffa700;
  --grey: #ccced0;

  /* Sizes */
  --phone: 481px;
  --tablet: 961px;
  --desktop: 1025px;
  --large-desktop: 1281px;

  /* TODO: Look into removing these */
  --orange: #FF8900;
  --quick-add-yellow: #fff8ea;
  --quick-add-green: #e7fcf8;
  --quick-add-pink: #fff0ff;
  --quick-add-blue: #fafaff;
}

input {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-input {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-cascader-picker {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-input-number-input {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-picker {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-select {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-select-item {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--secondary);
  font-family: 'font-primary', sans-serif;
}

.ant-picker-input>input {
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
}

.ant-form-item-label>label {
  color: var(--secondary);
  font-family: 'font-primary', sans-serif;
}

.ant-divider {
  border-color: var(--borders) !important;
}

/* Fonts */
@font-face {
  font-family: 'font-primary';
  src: url('./fonts/epilogue/Epilogue-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 400;
}

@font-face {
  font-family: 'font-primary-semibold';
  src: url('./fonts/epilogue/Epilogue-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 600;
}

@font-face {
  font-family: 'font-primary-bold';
  src: url('./fonts/epilogue/Epilogue-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 700;
}

@font-face {
  font-family: 'font-primary-heavy';
  src: url('./fonts/epilogue/Epilogue-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 800;
}
/* MyCarousel.css */
.image-wrapper {
  border: 2px solid var(--white);
  transition: border-color 0.3s ease;
  border-radius: 8px;
}

.image-wrapper.selected {
  border-color: var(--accent-variant);
}
