.facebook__button {
    background-color: var(--white);
    border: 1px solid var(--borders);
    color: var(--primary);
    width: 100%;
    text-align: center;
    height: 40px;
    border-radius: 4px;
    font-family: "'font-primary', sans-serif";
    font-weight: bold;
    opacity: 1;
    justify-content: center;
}

.facebook__button:hover {
    color: black;
    border: 1px solid #b6b6be;
}

.facebook__button>img {
    width: 20px;
}

.facebook__button>span {
    margin-left: 16px;
}

@media (min-width: 1200px) {
    .facebook__button {
        min-width: 200px;
    }

}